import React from 'react'
import { Container } from 'reactstrap'

export const WorkExperience = () => (
  <section id="projects">
    <Container>
      <h2 className="navpad">Work Experience</h2>
      TODO
    </Container>
  </section>
)

export default WorkExperience